import React, { useState } from 'react';
import { Container } from 'semantic-ui-react';
import logo from '../../assets/images/Logo-FirenzeFiera-White-AlboFornitori.png';

const HoverLink = ({ href, children }) => {
    const [hover, setHover] = useState(false);

    const handleMouseEnter = () => {
        setHover(true);
    };

    const handleMouseLeave = () => {
        setHover(false);
    };

    const linkStyle = {
        color: 'black',
        textDecoration: hover ? 'underline' : 'none',
        marginRight: '8px',
    };

    const bgHover = {
        width: '26px',
        height: '26px',
        padding: '20px',
        border: '1px solid #000000',
        borderRadius: '50%',
        backgroundColor: hover ? '#000000' : '#ffffff',
        alignItems: 'center',
        display: 'flex',
        marginTop: '5px',
    };

    const arrowStyle = {
        fontSize: '30px',
        fontWeight: '100',
        transform: 'translateX(-10px) translateY(2px)',
        color: hover ? 'white' : 'black',
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center', fontSize: '16px', paddingBottom: '16px'}}>
            <a
                style={linkStyle}
                aria-label={`Vai a ${children}`}
                href={href}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {children}
            </a>
            <div
                id={'hover'}
                style={bgHover}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <p style={arrowStyle}>
                    &rarr;
                </p>
            </div>
        </div>
    );
};

const NavigationMenu = () => {
    return (
        <Container fluid>
            <div style={{
                backgroundColor: "#ffffff",
                lineHeight: '24px',
                paddingBottom: '24px',
                paddingTop: '24px',
                paddingRight: '15px',
                paddingLeft: '15px',
                borderBottom: '1px solid #000000',
                display: 'flex'
            }}>
                <div style={{ width: '13%' }}>
                    <img src={logo} width={'75%'} />
                </div>
                <div style={{ width: '29%' }}>
                    <HoverLink href="https://areariservata.firenzefiera.it/it/login">Area riservata</HoverLink>
                    <HoverLink href="https://www.firenzefiera.it/profilo-aziendale">Profilo Aziendale</HoverLink>
                </div>
                <div style={{ width: '29%' }}>
                    <HoverLink href="https://www.firenzefiera.it/press-area">Area Stampa</HoverLink>
                    <HoverLink href="https://www.firenzefiera.it/societa-trasparente">Società Trasparente</HoverLink>
                </div>
                <div style={{ width: '29%' }}>
                    <HoverLink href="https://www.firenzefiera.it/area-fornitori">Area Fornitori</HoverLink>
                    <HoverLink href="https://www.firenzefiera.it/profilo-aziendale/lavora-con-noi">Lavora con noi</HoverLink>
                </div>
            </div>
        </Container>
    );
};

export default NavigationMenu;