import React from 'react';
import { Container, Icon } from 'semantic-ui-react';

const SocialMediaLinks = () => (
  <Container fluid>
    <div style={{backgroundColor: "#ffffff", display: 'flex', paddingBottom:'24px', paddingTop:'24px', paddingRight:'15px', paddingLeft:'15px', justifyContent:'space-around', fontSize:'1.25rem', borderBottom:'1px solid #000000'}}>
        <div style={{ width:'25%', fontSize:'20px', letterSpacing:'-.4px', justifyContent:'center', display:'flex'}}>
            <a style={{color:'black'}} aria-label="Vai a Spazi" href="https://www.firenzefiera.it/spazi/fortezza-da-basso/padiglione-spadolini">Spazi</a>
        </div>
        <div style={{ width:'25%', fontSize:'20px', letterSpacing:'-.4px', justifyContent:'center', display:'flex' }}>
            <a style={{color:'black'}} aria-label="Vai a Eventi" href="https://www.firenzefiera.it/eventi">Eventi</a>
        </div>
        <div style={{ width:'25%', fontSize:'20px', letterSpacing:'-.4px', justifyContent:'center', display:'flex' }}>
            <a style={{color:'black'}} aria-label="Vai a Organizza" href="https://www.firenzefiera.it/organizza">Organizza</a>
        </div>
        <div style={{ width:'25%', fontSize:'20px', letterSpacing:'-.4px', justifyContent:'center', display:'flex' }}>
            <a style={{color:'black'}} aria-label="Vai a Visita" href="https://www.firenzefiera.it/visita">Visita</a>
        </div>
    </div>
  </Container>
);

export default SocialMediaLinks;