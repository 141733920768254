import React from 'react';
import { Container, Header, Button } from 'semantic-ui-react';

const NewsletterSection = () => (
  <Container fluid>

      <div style= {{width: "100%", backgroundColor: "#f8f8f8", display: 'flex', paddingBottom:'96px', paddingTop:'48px'}}>
          <div style={{marginLeft:'10px', width:'75%'}} className={"container-fluid py-5"}>
              <div className={"row pb-5"}>
                  <div className={"col-md-9"}>
                      <div className={"d-inline-block mr-4"}>
                          <div style={{fontWeight: "400", fontSize: "2.125rem", lineHeight: "1.5"}}>
                              Iscriviti alla newsletter
                          </div>
                      </div>
                  </div>
                  <div className={"col-md-3 mt-5"}>
                      <p style={{lineHeight: '1.4', paddingBottom: '1rem', marginTop: "16px"}}>Rimani aggiornato su
                          tutte le novità, gli spazi,
                          gli eventi di Firenze Fiera
                      </p>
                  </div>
                  <div style={{backgroundColor: "#000000", paddingLeft:'30px', paddingRight:'30px', paddingTop:'15px', paddingBottom:'15px', width:"100px"}}>
                      <a style={{color: "#ffffff"}} href="https://www.firenzefiera.it/iscriviti-alla-nostra-newsletter">Iscriviti</a>
                  </div>
              </div>
          </div>
          <div style={{width:'25%', paddingTop:'24px', paddingLeft:'10px', paddingRight:'10px'}}>
              <p>
                  Seguici sui nosstri social network
              </p>
              <div style={{display:'flex', paddingTop:'10px'}}>
                  <a style={{paddingRight:'24px'}} href="https://www.facebook.com/firenzefieraofficial">
                      <img alt="Facebook"
                               width={'25px'}
                               style={{display:'flex', justifyContent:'flex-start'}}
                               src="https://admin.firenzefiera.it/wp-content/uploads/2020/12/components-icons-facebook.svg"/>
                  </a>
                  <a style={{paddingRight:'24px'}} href="https://www.instagram.com/firenzefiera/">
                      <img alt="Facebook"
                           width={'25px'}
                           style={{display:'flex', justifyContent:'flex-start'}}
                           src="https://admin.firenzefiera.it/wp-content/uploads/2020/12/components-icons-instagram.svg"/>
                  </a>
                  <a style={{paddingRight:'24px'}} href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQGGGaNlEfSbeQAAAZS73BAI7MqweeRi1C1Yrsz7JbFYDXgTwtqnjknaHlD--1eemi4bv0ygX2JF55jvd8zU484z3r83TekC0MaWFgEgUBksXTV33NL53uuByuPqNzhRNIET0Ew=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Ffirenze-fiera-spa%2Fhttps://www.linkedin.com/authwall?trk=bf&trkInfo=AQGGGaNlEfSbeQAAAZS73BAI7MqweeRi1C1Yrsz7JbFYDXgTwtqnjknaHlD--1eemi4bv0ygX2JF55jvd8zU484z3r83TekC0MaWFgEgUBksXTV33NL53uuByuPqNzhRNIET0Ew=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Ffirenze-fiera-spa%2F">
                      <img alt="Facebook"
                           width={'25px'}
                           style={{display:'flex', justifyContent:'flex-start'}}
                           src="https://admin.firenzefiera.it/wp-content/uploads/2020/12/components-icons-linkedin.svg"/>
                  </a>
              </div>
          </div>
      </div>
  </Container>
);

export default NewsletterSection;