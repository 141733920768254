import React from "react"
import NewsletterSection from "./Footer/NewsletterSection"
import SocialMediaLinks from "./Footer/SocialMediaLinks"
import NavigationMenu from "./Footer/NavigationMenu"
import SecondaryNavigation from "./Footer/SecondaryNavigation"
import { Container, Divider, Icon, List, Table } from "semantic-ui-react"

const Footer = () => (
  <footer id="main_footer" style={{ fontFamily: "MontRegular" }}>
    <NewsletterSection/>
    <SocialMediaLinks/>
    <NavigationMenu/>
    <SecondaryNavigation/>
  </footer>
)

export default Footer
