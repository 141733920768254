import React, { useEffect } from 'react';
import { Container } from 'semantic-ui-react';
import cert_1 from '../../assets/images/certificazione_1.png';
import cert_2 from '../../assets/images/certificazione_2.png';
import cert_4 from '../../assets/images/certificazione_4.png';

const SecondaryNavigation = () => {

    return (
        <Container fluid>
            <div style={{
                backgroundColor: "#ffffff",
                lineHeight: '24px',
                paddingBottom: '24px',
                paddingTop: '24px',
                paddingRight: '15px',
                paddingLeft: '15px',
                borderBottom: '1px solid #000000',
                display:'flex',
            }}>
                <div style={{width:'25%'}}>
                    <div style={{display:'flex', justifyContent:'space-between', textAlign:'center'}}>
                        <div style={{width:'50%', fontSize: "14.8px", lineHeight: "22.2px", fontWeight: "500", borderRight:'3px solid #DADADA'}}>
                            <a style={{color:'black'}} href="https://www.firenzefiera.it/privacy">Norme sulla privacy</a>
                        </div>
                        <div style={{width:'50%', fontSize: "14.8px", lineHeight: "22.2px", fontWeight: "500"}}>
                            <a style={{color:'black'}} href="https://www.firenzefiera.it/privacy#cookieP">Utilizzo dei Cookie</a>
                        </div>
                    </div>
                    <div style={{paddingTop:'24px', paddingBottom:'48px', color:'rgb(104,104,104)', fontSize:'12px'}}>
                        ©2023 Firenze Fiera CCIAA di Firenze
                    </div>
                </div>
                <div style={{width:'33%'}}>
                    <div style={{width:'50%', fontSize: "14.8px", lineHeight: "22.2px", fontWeight: "600", paddingLeft:'20px'}}>
                        Certificazioni:
                    </div>
                    <div style={{paddingLeft: '20px', display:'flex'}}>
                        <div style={{width:'70px', height: '84px',maxWidth:'100px'}}>
                            <img src={cert_1} width={'100%'}/>
                        </div>
                        <div style={{width:'70px', height: '84px', maxWidth:'100px'}}>
                            <img src={cert_2} width={'100%'}/>
                        </div>
                        <div style={{width:'70px', height: '84px', maxWidth:'100px'}}>
                            <a href={'https://admin.firenzefiera.it/wp-content/uploads/2024/04/ISO-201212012-C643660-1-it-IT-20240329-20240329144452.pdf'}>
                                <img src={cert_1} width={'100%'}/>
                            </a>
                        </div>
                        <div style={{width:'70px', height: '84px', maxWidth:'100px', paddingLeft:'2px'}}>
                            <img src={cert_4} width={'100%'}/>
                        </div>
                    </div>
                </div>
                <div style={{width: '17%', fontSize:'16px', fontWeight:'700', lineHeight:'24px', paddingLeft:'20px'}}>
                    <a style={{color:'black'}} role="button" href="https://www.firenzefiera.it/dichiarazione-di-accessibilita">Dichiarazione di accessibilita</a>
                </div>
                <div style={{width:'30%', flexDirection:'column', textAlign:'center', paddingLeft:'50px'}}>
                    <div style={{display:'flex'}}>
                        <div style={{fontSize:'16px', paddingRight:'4px'}}>
                            Lingua:
                        </div>
                        <div style={{fontSize: '16px', fontWeight: '500'}}>
                            <a style={{color:'black'}} href="https://www.firenzefiera.it/en" aria-label="English">EN</a>
                        </div>
                    </div>
                    <div style={{display: 'flex', lineHeight: '16.8px', color: 'rgb(104, 104, 104)', fontSize:'12px'}}>
                        P.I. 04933280481
                    </div>
                    <div style={{display:'flex', lineHeight:'16.8px', color:'rgb(104, 104, 104)', fontSize:'12px'}}>
                        Capitale versato: €
                    </div>
                    <div style={{display:'flex', lineHeight:'16.8px', color:'rgb(104, 104, 104)', fontSize:'12px'}}>
                        21.778.035,84
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default SecondaryNavigation;