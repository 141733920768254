import React from "react"
import { Link, navigate } from "gatsby"
import { isLoggedIn, logout } from "./Shared/Auth"
import { Container, List, Table, Image, Icon } from "semantic-ui-react"
import LocalStorageManager from "./Shared/LocalStorageManager"
import logoFF from "../assets/images/Logo-FirenzeFiera-White-AlboFornitori.png"
import Constants from "./Shared/Constants";

export default () => {
  const userStatus = { message: "", login: true }
  const appVersion = { message: ""}
  if (isLoggedIn()) {
    const username = LocalStorageManager.getKey("username")
    userStatus.message = "Utente: " + username
  } else {
    userStatus.message = "Non hai fatto login nel sistema"
  }
  appVersion.message = "" + Constants.APP_VERSION

  return (
    <Container fluid style={{
      backgroundColor: "#ffffff",
      color: "#000",
      paddingTop: "1em",
      paddingBottom: "em",
      paddingLeft: "1em",
      paddingRight: "1em",
      marginBottom: "em",
      fontFamily:'MontRegular'
    }}>
        <Table columns={3} style={{
            backgroundColor: 'transparent',
            border:"0px",
            color: "#000",
            padding: "0em 2em 0em 2em",
            textAlign: "center",
        }}>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>
                        <Link to="/app/home">
                            <Image src={logoFF} alt={"FirenzeFiera"} style={{width: 120}} centered/>
                        </Link>
                    </Table.Cell>
                    <Table.Cell>
                        <h1 style={{fontFamily:'MontSemiBold'}}>
                            <Icon name={"address book outline"} size={"large"}/>
                            Albo Fornitori
                        </h1>
                    </Table.Cell>
                    <Table.Cell>
                        <h4 style={{fontFamily:'MontRegular'}}>
                            <Icon name={"user outline"} />
                            {userStatus.message}
                        </h4>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <h4>
                            <List horizontal size={"large"}>
                                <List.Item>
                                    <a href={`${Constants.PATH_PREFIX}/HELP_albo fornitori gestione versione intestata quarkomp.pdf`} style={{ color: "black" }}>
                                        <List.Icon name={"file pdf outline"}/>
                                         {appVersion.message}
                                    </a>
                                </List.Item>
                            </List>
                        </h4>
                    </Table.Cell>
                    <Table.Cell/>
                    <Table.Cell>
                        <List horizontal size={"large"}>
                            <List.Item>
                                <Link to="/app/home" style={{ color: "black" }}>
                                    <List.Icon name={"home"}/>
                                    Home
                                </Link>
                            </List.Item>

                            {isLoggedIn() ?
                                <List.Item>
                                    <a style={{ color: "black" }}
                                        href="/"
                                        onClick={event => {
                                            event.preventDefault()
                                            logout(() => navigate("/app/login"))
                                        }}
                                    >
                                        <List.Icon name={"sign out alternate"}/>
                                        Logout
                                    </a>
                                </List.Item>
                                :
                                <List.Item>
                                    <Link as={"h4"} to="/app/retrieve/password" style={{ color: "black" }}>
                                        <List.Icon name={"recycle"}/>
                                        Recupera password
                                    </Link>
                                </List.Item>
                            }
                        </List>
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    </Container>
      )
}