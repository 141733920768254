import React from "react"
import { Container } from "semantic-ui-react"
import Footer from "./Footer"
import Navbar from "./Navbar"
import { ToastContainer } from "react-toastify"
import RestManager from "./Shared/RestManager"
import favicon from "../assets/images/favicon-96x96.png"
import Helmet from "react-helmet"
import font from "../assets/styles/MyWebfontsKit.css"

const Layout = ({ children }) => (
  <>
    <Helmet>
      <link rel="icon" href={favicon}/>
    </Helmet>

    {RestManager.configureInterceptors()}

    <Navbar/>

        <Container style={{minHeight: "44.2vh", fontFamily:'MontRegular', marginTop:'25px', marginBottom:'25px'}}>{children}</Container>

    <Footer/>

    <ToastContainer
      position={"top-center"}
      autoClose={3500}
    />
  </>
)

export default Layout
